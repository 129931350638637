import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import credits from "../../assets/credits.js";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setColorForAll } from "../../redux/actions/theme";
import { useEffect, useState } from "react";
import { clearPokeData } from "../../redux/actions/pokemon-by-id";

export default function Home(params) {
  const dispatch = useDispatch();
  const { color } = useSelector((state) => state.themeReducerSlice);
  const handleColorChange = (event) => {
    dispatch(setColorForAll(event.target.value));
  };
  const navigate = useNavigate();
  const handlePickFromListClick = () => {
    navigate("/pokemons");
  };
  const handlePickByIdClick = () => {
    dispatch(clearPokeData());
    navigate("/pokemons/detailbyid");
  };

  return (
    <div
      className="flex flex-col w-screen h-screen items-center"
      style={{ backgroundColor: color }}
    >
      <div className="h-2/5">
        <Banner />
      </div>
      <div className="h-2/5">
        <p className="text-xl mt-30">
          Una aplicación de demostración que muestra un listado de Pokemons.
          Luego podremos ver los detalles de cada uno en una vista de detalles.
          También tiene como objetivo demostrar el uso de Redux para el manejo
          de los estados. Utilizaremos Redux Toolkit, en particular RTK Query.
        </p>
        <div className="flex flex-row w-screen justify-center">
          <button
            onClick={handlePickFromListClick}
            className=" text-white rounded-full bg-red-400 text-xl py-2 px-2 mx-2 my-10 hover:ring-2 hover:ring-red-600"
          >
            Pokémon From List
          </button>
          <button
            onClick={handlePickByIdClick}
            className=" text-white rounded-full bg-red-400 text-xl py-2 px-2 mx-2 my-10 hover:ring-2 hover:ring-red-600"
          >
            Pokémon By Id
          </button>
        </div>
      </div>
      <div className="bg-slate-300 w-full">
        <Footer appName="PokeApp" credits={credits} />
        <input type="color" onChange={handleColorChange} value={color}></input>
      </div>
    </div>
  );
}
