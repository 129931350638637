import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import credits from "../../assets/credits";
import PokemonGeneralInfo from "../PokemonDetail/components/PokemonGeneralInfo";
import PokemonStatsInfo from "../PokemonDetail/components/PokemonStatsInfo";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { fetchPokeDataById } from "../../redux/actions/pokemon-by-id";


export default function PokemonDetailById() {

  const dispatch = useDispatch();

  const [pokemonId, setPokemonId] = useState("1");

  const handlePokeIdSelectorChange = (event) => {
    if (event.target.value < 501 && event.target.value > -1) {
      setPokemonId(event.target.value);
    }
  };
  const handleGetThisPokemonClick = () => {
    if(pokemonId!=0){

      dispatch(fetchPokeDataById(pokemonId));
    } else {
      alert("Pokemon Id can´t be 0")
      setPokemonId("1");
    }
  };
  const handlePokeIdKeyDown = (event) => {
    //event.preventDefault();
  };
  const {
    isFetchingPokemonData,
    isLoadingPokeData,
    errorFetchingPokemonData,
    successFetchingPokemonData,
    pokemonData,
  } = useSelector((state) => state.pokemonByIdReducer);
  const { color } = useSelector((state) => state.themeReducerSlice);

  if (errorFetchingPokemonData) {
    return <p>Something really bad has happened :) ...</p>;
  } else if (isLoadingPokeData || isFetchingPokemonData) {
    return (
      <Loading message="We are seraching for pokemon details, please wait :) ..." />
    );
  } else if (successFetchingPokemonData) {
    return (
      <div
        className="flex flex-col w-screen items-center"
        style={{ backgroundColor: color }}
      >
        <div className="h-2/5">
          <Banner />
        </div>
        <div className="flex flex-row items-center py-2">
          <button
            onClick={handleGetThisPokemonClick}
            className="text-white rounded-full bg-red-400 text-lg  px-1 py-1 hover:ring-2 hover:ring-red-600 mx-1"
          >
            Get This Pokémon
          </button>
          <input
            className="w-14 h-9 pl-2 mx-1 rounded-full border-2 border-red-500"
            type="number"
            min="1"
            max="500"
            value={pokemonId}
            onChange={handlePokeIdSelectorChange}
            onKeyDown={handlePokeIdKeyDown}
          />
        </div>
        <div className="h-1/5">
          <PokemonGeneralInfo
            pokemonName={pokemonData?.name}
            pokemonWeight={pokemonData?.weight}
            pokemonHeight={pokemonData?.height}
          />
        </div>
        <div>
          <PokemonStatsInfo pokemonStats={pokemonData?.stats} />
        </div>
        <div className="bg-slate-300 w-full">
          <Footer credits={credits} />
        </div>
      </div>
    );
  }
}
