import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    color: "#82e884"
};

const themeSlice = createSlice({
    name: "theme-slice",
    initialState,
    reducers: {
        setThemeColor(state,action){
            state.color=action.payload.color;
        }
    }
});

const {actions,reducer} = themeSlice;

const {setThemeColor} = actions;

export {setThemeColor};

export default reducer;

