import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import { pokemonDetailApi, pokemonsApi } from '../redux/api/pokemons';
import themeReducerSlice from "../redux/slices/theme";
import pokemonByIdReducer from "../redux/slices/pokemon-by-id"


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [pokemonsApi.reducerPath]: pokemonsApi.reducer,
    [pokemonDetailApi.reducerPath]:pokemonDetailApi.reducer,
    themeReducerSlice,
    pokemonByIdReducer,

  },
  middleware: (getDefaultMiddleware)=> getDefaultMiddleware().concat(pokemonsApi.middleware),
});
