import {BrowserRouter,Routes,Route} from "react-router-dom";

import Home from "../views/Home";
import PokemonList from "../views/PokemonList";
import PokemonDetail from "../views/PokemonDetail";
import PokemonDetailById from "../views/PokemonDetailById";

export default function RoutesComponent() {
    return (
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/pokemons" element={<PokemonList/>}/>
            <Route path="/pokemons/detail/:pokemonId" element={<PokemonDetail/>}/>
            <Route path="/pokemons/detailbyid" element={<PokemonDetailById/>}/>
        </Routes>
        </BrowserRouter>
    );
}