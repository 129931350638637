import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import credits from "../../assets/credits.js";
import PokemonListItem from "./components/PokemonListItem";
import { useFetchPokemonsQuery } from "../../redux/api/pokemons";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";

export default function PokemonList() {
  const {color} = useSelector((state)=>state.themeReducerSlice);
  const {
    data: pokemons,
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = useFetchPokemonsQuery();
  if (isError) {
    return <p>Something really bad has happened :) ...</p>;
  } else if (isLoading || isFetching) {
    return (
      <Loading message="We are loading your pokemon list, please wait :) ..." />
    );
  } else if (isSuccess) {
    return (
      <div className="flex flex-col w-screen items-center" style={{backgroundColor:color}}>
        <div className="h-2/5">
          <Banner />
        </div>
        <div className="h-2/5 w-screen grid grid-cols-4 place-content-evenly">
          {pokemons?.results.map((pokemon) => (
            <PokemonListItem
              pokemonName={pokemon.name}
              pokemonId={pokemon.url.split("/")[6]}
            />
          ))}
        </div>
        <div className="bg-slate-300 w-full">
          <Footer credits={credits} />
        </div>
      </div>
    );
  }
}
